import {
  clone,
  findIndex,
  get,
  has,
  isUndefined,
  merge,
  set,
  setWith,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import arrayMove from 'array-move';
import i18next from 'i18next';
import demoState from '../data/demoState.json';
import DatabaseContext from './DatabaseContext';
import initialState from '../data/initialState.json';

const ResumeContext = createContext({});

var deepDiffMapper = function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: '---',
    map: function ( obj1, obj2 ) {
      if ( this.isFunction( obj1 ) || this.isFunction( obj2 ) ) {
        throw 'Invalid argument. Function given, object expected.';
      }
      if ( this.isValue( obj1 ) || this.isValue( obj2 ) ) {
        let returnObj = {
          type: this.compareValues( obj1, obj2 ),
          original: obj1,
          updated: obj2,
        };
        if ( returnObj.type != this.VALUE_UNCHANGED ) {
          return returnObj;
        }
        return undefined;
      }

      var diff = {};
      let foundKeys = {};
      for ( var key in obj1 ) {
        if ( this.isFunction( obj1[ key ] ) ) {
          continue;
        }

        var value2 = undefined;
        if ( obj2[ key ] !== undefined ) {
          value2 = obj2[ key ];
        }

        let mapValue = this.map( obj1[ key ], value2 );
        foundKeys[ key ] = true;
        if ( mapValue ) {
          diff[ key ] = mapValue;
        }
      }
      for ( var key in obj2 ) {
        if ( this.isFunction( obj2[ key ] ) || foundKeys[ key ] !== undefined ) {
          continue;
        }

        let mapValue = this.map( undefined, obj2[ key ] );
        if ( mapValue ) {
          diff[ key ] = mapValue;
        }
      }

      //2020-06-13: object length code copied from https://stackoverflow.com/a/13190981/2336212
      if ( Object.keys( diff ).length > 0 ) {
        return diff;
      }
      return undefined;
    },
    compareValues: function ( value1, value2 ) {
      if ( value1 === value2 ) {
        return this.VALUE_UNCHANGED;
      }
      if ( this.isDate( value1 ) && this.isDate( value2 ) && value1.getTime() === value2.getTime() ) {
        return this.VALUE_UNCHANGED;
      }
      if ( value1 === undefined ) {
        return this.VALUE_CREATED;
      }
      if ( value2 === undefined ) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function ( x ) {
      return Object.prototype.toString.call( x ) === '[object Function]';
    },
    isArray: function ( x ) {
      return Object.prototype.toString.call( x ) === '[object Array]';
    },
    isDate: function ( x ) {
      return Object.prototype.toString.call( x ) === '[object Date]';
    },
    isObject: function ( x ) {
      return Object.prototype.toString.call( x ) === '[object Object]';
    },
    isValue: function ( x ) {
      return !this.isObject( x ) && !this.isArray( x );
    }
  };
}();

const ResumeProvider = ({ children }) => {
  const { debouncedUpdateResume } = useContext(DatabaseContext);

  const memoizedReducer = useCallback(
    (state, { type, payload }) => {
      let newState;
      let index;
      let items;
      let temp;

      switch (type) {
        case 'on_add_item':
          delete payload.value.temp;
          items = get(state, payload.path, []);
          newState = setWith(
            clone(state),
            payload.path,
            [...items, payload.value],
            clone,
          );
          debouncedUpdateResume(newState);
          console.log( "on_add_item state => ", state );
          console.log( "on_add_item newState => ", newState );
          console.log( "on_add_item items => ", items );
          return newState;

        case 'on_edit_item':
          delete payload.value.temp;
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          newState = setWith(
            clone(state),
            `${payload.path}[${index}]`,
            payload.value,
            clone,
          );
          debouncedUpdateResume(newState);
          console.log( "on_edit_item state => ", state );
          console.log( "on_edit_item newState => ", newState );
          console.log( "on_edit_item items => ", items );
          return newState;

        case 'on_delete_item':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          items.splice(index, 1);
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          console.log( "on_delete_item state => ", state );
          console.log( "on_delete_item newState => ", newState );
          console.log( "on_delete_item items => ", items );
          return newState;

        case 'on_toggle_use_item':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          if ('isVisible' in items[index]) {
            items[index].isVisible = !items[index].isVisible;
          } else {
            items[index].isVisible = false;
          }
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          console.log( "on_toggle_use_item state => ", state );
          console.log( "on_toggle_use_item newState => ", newState );
          console.log( "on_toggle_use_item items => ", items );
          return newState;

        case 'on_move_item_up':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          items = arrayMove(items, index, index - 1);
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          console.log( "on_move_item_up state => ", state );
          console.log( "on_move_item_up newState => ", newState );
          console.log( "on_move_item_up items => ", items );
          return newState;

        case 'on_move_item_down':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          items = arrayMove(items, index, index + 1);
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          console.log( "on_move_item_down state => ", state );
          console.log( "on_move_item_down newState => ", newState );
          console.log( "on_move_item_down items => ", items );
          return newState;

        case 'change_language':
          newState = set(clone(state), 'metadata.language', payload);
          items = get(
            i18next.getDataByLanguage(payload),
            'translation.builder.sections',
          );
          Object.keys(items).forEach((key) => {
            has(newState, `${key}.heading`) &&
              set(newState, `${key}.heading`, items[key]);
          });
          debouncedUpdateResume(newState);
          console.log( "change_language state => ", state );
          console.log( "change_language newState => ", newState );
          console.log( "change_language items => ", items );
          return newState;

        case 'reset_layout':
          temp = get(state, 'metadata.template');
          items = get(initialState, `metadata.layout.${temp}`);
          newState = setWith(
            clone(state),
            `metadata.layout.${temp}`,
            items,
            clone,
          );
          debouncedUpdateResume(newState);
          console.log( "reset_layout state => ", state );
          console.log( "reset_layout newState => ", newState );
          console.log( "reset_layout items => ", items );
          return newState;

        case 'on_input':
          newState = setWith(clone(state), payload.path, payload.value, clone);
          debouncedUpdateResume(newState);
          console.log( "on_input payload => ", payload );
          console.log( "on_input state => ", state );
          console.log( "on_input items => ", items );

          let diff = deepDiffMapper.map(state, newState);

          console.log("diff -> ", diff, payload);

          return newState;

        case 'on_import':
          temp = clone(state);
          newState = payload;
          newState.id = temp.id;
          newState.user = temp.user;
          newState.name = temp.name;
          newState.createdAt = temp.createdAt;
          newState.updatedAt = temp.updatedAt;
          debouncedUpdateResume(newState);
          console.log( "on_import state => ", state );
          console.log( "on_import newState => ", newState );
          console.log( "on_import items => ", items );
          return newState;

        case 'on_import_jsonresume':
          temp = clone(state);
          newState = initialState;
          newState.id = temp.id;
          newState.user = temp.user;
          newState.name = temp.name;
          newState.preview = temp.preview;
          newState.createdAt = temp.createdAt;
          newState.updatedAt = temp.updatedAt;
          newState.profile = {
            address: {
              city: get(payload, 'basics.location.city'),
              line1: get(payload, 'basics.location.address'),
              line2: get(payload, 'basics.location.region'),
              pincode: get(payload, 'basics.location.postalCode'),
            },
            email: get(payload, 'basics.email'),
            firstName: get(payload, 'basics.name'),
            phone: get(payload, 'basics.phone'),
            photograph: get(payload, 'basics.picture'),
            subtitle: get(payload, 'basics.label'),
            website: get(payload, 'basics.website'),
          };
          newState.social.items = get(payload, 'basics.profiles')
            ? payload.basics.profiles.map((x) => ({
                id: uuidv4(),
                network: x.network,
                username: x.username,
                url: x.url,
              }))
            : [];
          newState.objective.body = get(payload, 'basics.summary');
          newState.character.body = get( payload, 'basics.character' );
          newState.work.items = payload.work
            ? payload.work.map((x) => ({
                id: uuidv4(),
                company: x.company,
                endDate: x.endDate,
                position: x.position,
                startDate: x.startDate,
                summary: x.summary,
                website: x.website,
              }))
            : [];
          newState.education.items = payload.education
            ? payload.education.map((x) => ({
                id: uuidv4(),
                degree: x.studyType,
                endDate: x.endDate,
                field: x.area,
                gpa: x.gpa,
                institution: x.institution,
                startDate: x.startDate,
                summary: x.courses.join('\n'),
              }))
            : [];
          newState.awards.items = payload.awards
            ? payload.awards.map((x) => ({
                id: uuidv4(),
                awarder: x.awarder,
                date: x.date,
                summary: x.summary,
                title: x.title,
              }))
            : [];
          newState.skills.items = payload.skills
            ? payload.skills.map((x) => ({
                id: uuidv4(),
                level: 'Fundamental Awareness',
                name: x.name,
              }))
            : [];
          newState.hobbies.items = payload.interests
            ? payload.interests.map((x) => ({
                id: uuidv4(),
                name: x.name,
              }))
            : [];
          newState.languages.items = payload.languages
            ? payload.languages.map((x) => ({
                id: uuidv4(),
                name: x.language,
                fluency: x.fluency,
              }))
            : [];
          newState.references.items = payload.references
            ? payload.references.map((x) => ({
                id: uuidv4(),
                name: x.name,
                summary: x.reference,
              }))
            : [];
          debouncedUpdateResume(newState);
          console.log( "on_import_jsonresume state => ", state );
          console.log( "on_import_jsonresume newState => ", newState );
          console.log( "on_import_jsonresume items => ", items );
          return newState;

        case 'set_data':
          newState = payload;
          debouncedUpdateResume(newState);
          console.log( "set_data state => ", state );
          console.log( "set_data newState => ", newState );
          console.log( "set_data payload => ", payload );
          return newState;

        case 'reset_data':
          temp = clone(state);
          newState = initialState;
          newState.id = temp.id;
          newState.user = temp.user;
          newState.name = temp.name;
          newState.preview = temp.preview;
          newState.createdAt = temp.createdAt;
          newState.updatedAt = temp.updatedAt;
          debouncedUpdateResume(newState);
          console.log( "reset_data state => ", state );
          console.log( "reset_data newState => ", newState );
          console.log( "reset_data items => ", items );
          return newState;

        case 'load_demo_data':
          newState = merge(clone(state), demoState);
          newState.metadata.layout = demoState.metadata.layout;
          debouncedUpdateResume(newState);
          console.log( "load_demo_data state => ", state );
          console.log( "load_demo_data newState => ", newState );
          console.log( "load_demo_data items => ", items );
          return newState;

        default:
          throw new Error();
      }
    },
    [debouncedUpdateResume],
  );

  const [state, dispatch] = useReducer(memoizedReducer, initialState);

  return (
    <ResumeContext.Provider value={{ state, dispatch }}>
      {children}
    </ResumeContext.Provider>
  );
};

const useSelector = (path, fallback) => {
  const { state } = useContext(ResumeContext);
  let value = get(state, path);

  if (isUndefined(value)) {
    value = isUndefined(fallback) ? state : fallback;
  }

  return value;
};

const useDispatch = () => {
  const { dispatch } = useContext(ResumeContext);
  return dispatch;
};

const memoizedProvider = memo(ResumeProvider);

export {
  ResumeContext,
  memoizedProvider as ResumeProvider,
  useSelector,
  useDispatch,
};

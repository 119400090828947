import { navigate } from '@reach/router';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import React, { createContext, memo, useEffect, useState } from 'react';
//import firebase from 'gatsby-plugin-firebase';
import useAuthState from '../hooks/useAuthState';

const defaultUser = {
  uid: 0,
  email: "",
  displayName: "",
  isAnonymous: false,
};

const defaultState = {
  loading: false,
  user: defaultUser,
  resumes: [],
  setResumes: () => {},
  logout: async () => {},
  loginWithGoogle: async () => {},
  loginAnonymously: async () => {},
  getUsersBoardDocuments: async () => {},
  loginWithEmail: async () => {},
  verifySession: async () => {},
  createUserJWT: async () => {},
  deleteAccount: async () => {},
};

const UserContext = createContext(defaultState);

const UserProvider = ({ children }) => {
//  const [firebaseUser, loading] = useAuthState(firebase);
  const [ loading, setLoading ] = useState(false);
  const [user, setUser] = useState({email: "", id: 0});
  const [userJWT, setUserJWT ] = useState(null);

  const [validSession, setSession] = useState(false);

  const [ resumes, setResumes ] = useState([]);

  const storage = window.localStorage;

  var endpoint = "";
  var endpoint2 = "";

  if ( typeof window !== `undefined` ) {
    if(window.location.origin == "localhost") {
      endpoint = "https://dashboard.boardsi.com";
      endpoint2 = "https://document.boardsi.com";
    } else {
      endpoint = "https://dashboard.boardsi.com";
      endpoint2 = "https://document.boardsi.com";
    }
  }

  useEffect(()=>{
    verifySession().then((results)=>{
      setSession( results );
      console.log("verifying session -> ", results)
      if(!results) {
        if ( !window.location.href.replace( window.location.origin, "" ).includes("/login") && window.location.href.replace( window.location.origin, "" ) !== "/" && /\/[r]\/{1}[\w]{6}\/$/.test(window.location.href) !== false)
          window.location.href = window.location.origin+"/login/";
      }
    });

    if(validSession && window.location.href.includes("/app/dashboard/")) {
      getUsersBoardDocuments();
    }
  }, [window.location.href])

  useEffect(() => {
//    const localUser = JSON.parse(localStorage.getItem('user'));
//   setUser(localUser);
  }, []);

  /*
  useEffect(() => {
    /*
    if (firebaseUser) {
      const remoteUser = pick(firebaseUser, Object.keys(defaultUser));
      localStorage.setItem('user', JSON.stringify(remoteUser));
      setUser(remoteUser);

      const addUserToDatabase = async () => {
        const userRef = firebase.database().ref(`users/${remoteUser.uid}`);
        const snapshot = await userRef.once('value');
        !snapshot.val() && userRef.set(remoteUser);
      };

      addUserToDatabase();
    }
    
  }, [firebaseUser]);
  */
  const loginWithMagicEmail = async () => {

  }

  const getUsersBoardDocuments = async () => {
    setLoading(true);
    const data = await fetch( endpoint2+"/api/v3/board-document/list/" );
    const result = await data.json();
    setResumes( result );
    setLoading(false);
  }

  const setUsersResumeArray = async (value) => {
    setResumes(value);
  }

  const loginWithEmail = async (userEmail, password) => {
    const body = { email: userEmail, password: password };
    const res = await fetch( endpoint+"/auth/v3/login/token", { method: 'POST', mode: 'cors', credentials: 'include', body: JSON.stringify( body ) } );
    const results = await res.json();

    console.log(results);

    const { valid, pid, email, error } = results;

    if(valid && error["err"] == null && email.includes('@')) {
      storage.setItem( "pid", pid );
      storage.setItem( "email", email );

      return results;
    }

    return {valid: false, email: ""};
  }

  const verifySession = async () => {
    const results = await fetch( endpoint+"/auth/v3/login/validate", { credentials: 'include' } );
    const data = await results.json();

    if(typeof data == "object" && "email" in data && "uid" in data && "valid" in data) {
      let { email, uid, valid } = data;

      if(!valid || email == "") {
        return false;
      }

      if ( email !== "" && data.valid ) {
        setUser({email: email, uid: uid})
        return true;
      }
    }

    return false;
  }

  const createCookie = async ( cookieName, cookieValue ) => {
    const date = new Date();
    date.setTime( date.getTime() + ( 15 * 60 * 1000 ) );
    document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toGMTString() +";samesite=Lax; secure;path=/";
  }

  const createUserJWT = async () => { // can only be ran if user has good current session

  }

  const loginWithGoogle = async () => {
    /*
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      return await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      toast.error(error.message);
    }
    */
  };

  const loginAnonymously = async () => {
    /*
    try {
      return await firebase.auth().signInAnonymously();
    } catch (error) {
      toast.error(error.message);
    }
    */
  };

  const logout = async () => {
    /*
    await firebase.auth().signOut();
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
    */
  };

  const reauthenticateWithGoogle = async () => {
    /*
    const { currentUser } = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const userCredential = await currentUser.reauthenticateWithPopup(
        provider,
      );
      return userCredential;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
    */
  };

  const reauthenticate = async () => {
    /*
    const { currentUser } = firebase.auth();

    if (currentUser.isAnonymous) {
      return;
    }

    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    const authProviderIsGoogle =
      currentUser.providerData &&
      currentUser.providerData.length > 0 &&
      currentUser.providerData[0].providerId === googleAuthProvider.providerId;

    if (authProviderIsGoogle) {
      await reauthenticateWithGoogle();
    } else {
      const errorMessage = 'Unable to determine reauthentication method.';
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
    */
  };

  const deleteAccount = async () => {
    /*
    const { currentUser } = firebase.auth();
    const deleteUser = firebase.functions().httpsCallable('deleteUser');

    await reauthenticate();

    await deleteUser();

    try {
      await currentUser.delete();
    } catch (error) {
      toast.error(error.message);
    } finally {
      await logout();
      toast(
        "It's sad to see you go, but we respect your privacy. All your data has been deleted successfully. Hope to see you again soon!",
      );
    }
    */
  };

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        loading,
        resumes,
        setResumes,
        loginWithGoogle,
        loginAnonymously,
        getUsersBoardDocuments,
        loginWithEmail,
        verifySession,
        loginWithMagicEmail,
        createUserJWT,
        deleteAccount,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

const memoizedProvider = memo(UserProvider);

export { memoizedProvider as UserProvider };

import { debounce } from 'lodash';
import React, { createContext, memo, useContext, useState } from 'react';
import ShortUniqueId from 'short-unique-id';
import { showNotification, updateNotification } from '@mantine/notifications';
//import firebase from 'gatsby-plugin-firebase';
import UserContext from './UserContext';
import { Check } from 'tabler-icons-react';
import { getUnsplashPhoto } from '../utils';
import initialState from '../data/initialState.json';

const DEBOUNCE_WAIT_TIME = 6000;

const defaultState = {
  isUpdating: false,
  createResume: async () => {},
  duplicateResume: async () => {},
  deleteResume: () => {},
  getResume: async () => {},
  getResumes: async () => {},
  updateResume: async () => {},
  debouncedUpdateResume: async () => {},
};

const DatabaseContext = createContext(defaultState);

const DatabaseProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const [isUpdating, setUpdating] = useState(false);
  const { user, setResumes, resumes, getUsersBoardDocuments } = useContext(UserContext);

  var endpoint = "";

  if ( typeof window !== `undefined` ) {
    if(window.location.origin == "localhost") {
      endpoint = "";
    } else {
      endpoint = "https://document.boardsi.com";
    }
  }

  const getResume = async (id) => {
    const data = await fetch( endpoint+`/api/v3/board-document/get/${id}/`, { credentials: 'include' } );
    const result = await data.json();
    let results = {}

    if(result.length > 0) {
      results = result[0];
    }

    console.log(result);

    return results;
  };

  const createResume = async ({ name }) => {
    var body = {name: name};

    const result = await fetch( endpoint+"/api/v3/board-document/create/", { method: 'POST', credentials: 'include', body: JSON.stringify( body ) } )
    const data = await result.json();

    setResumes([]);
    await getUsersBoardDocuments();

    setTimeout(()=>{
      showNotification( {
        disallowClose: false,
        autoClose: 7000,
        title: "Board Document " + name,
        message: 'was successfully created!',
        color: 'green',
        loading: false,
      } );
    }, 1500);

  };

  const duplicateResume = async (originalResume) => {
    const resume = {
      ...originalResume,
      name: `${originalResume.name}`,
//      preview: preview,
    };

    const result = await fetch( endpoint+"/api/v3/board-document/duplicate/", { method: 'POST', credentials: 'include', body: JSON.stringify( resume ) } )
    const data = await result.json();

    console.log(data);

    var newResumes = resumes;

    const {id, name, createdAt, updatedAt} = resume;
    newResumes.push({id, name, createdAt, updatedAt});
    setResumes(newResumes);

    setTimeout(()=>{
      updateNotification( {
        id: "boardDocDuplication",
        disallowClose: false,
        autoClose: 3000,
        title: "Board Document " + originalResume.name + " (copy)",
        message: 'was successfully created!',
        color: 'green',
        loading: false,
      } );
    }, 1500);
  };

  const updateResume = async (resume) => {
    setUpdating(true);

    const result = await fetch( endpoint+"/api/v3/board-document/update/", { method: 'POST', credentials: 'include', body: JSON.stringify( resume ) } );
    const data = await result.json();

    setTimeout(()=>{
      setUpdating(false);   
    }, 1500);
  };

  const debouncedUpdateResume = debounce(updateResume, DEBOUNCE_WAIT_TIME);

  const deleteResume = async (id) => {
    //await firebase.database().ref(`/resumes/${id}`).remove();
  };

  return (
    <DatabaseContext.Provider
      value={{
        isUpdating,
        getResume,
        createResume,
        duplicateResume,
        updateResume,
        deleteResume,
        debouncedUpdateResume,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContext;

const memoizedProvider = memo(DatabaseProvider);

export {
  memoizedProvider as DatabaseProvider,
  DEBOUNCE_WAIT_TIME as DebounceWaitTime,
};
